import axios from "axios";

const API_ENDPOINT = "https://mobileapp.nomadictribe.com/";
// const API_ENDPOINT = "https://mproduction.nomadictribe.com/";
const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});



/*eslint-disable */
class DataService {
  static get(path = "", id = null, params = "") {
    return client({
      method: "GET",
      url: path + `${id ? id : ""}`,
      params,
    });
  }



  static post(path = "", data = {}, optionalHeader = {}) {
    console.log(path, data);
    return client({
      method: "POST",
      url: path,
      data,
    });
  }

  static patch(path = "", id = null, data = {}) {
    return client({
      method: "PATCH",
      url: path + `${id ? id : ""}`,
      data: JSON.stringify(data),
    });
  }

  static delete(path = "", id = null) {
    return client({
      method: "DELETE",
      url: path + `${id ? id : ""}`,
    });
  }

  static put(path = "", id = null, data = {}) {
    return client({
      method: "PUT",
      url: path + `${id ? id : ""}`,
      data: JSON.stringify(data),
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  const token = localStorage.getItem("token");
  requestConfig.headers = {
    ...headers,
    Authorization: token,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    // if (response) {
    //     if (response.status === 500) {
    //         // do something here
    //     } else {
    //         return originalRequest;
    //     }
    // }
    return Promise.reject(error);
  }
);


class MultiPartDataService {
  static post(path = '', form = null, progress = null) {
    return client({
      method: 'POST',
      url: path,
      data: form,
      onUploadProgress: (progressEvent) => {
        progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    });
  }
}

/*eslint-enable */
export { DataService,MultiPartDataService };
