export default {
  home: ["admin"],
  vendorDashboard: ["vendor"],
  normalUser: ["admin"],
  vendorSettings: ["vendor"],
  NormalUsersdetails: ["admin"],
  NormalUserOverview: ["admin"],
  NormalUserFollowers: ["admin"],
  NormalUserFollowing: ["admin"],
  NormalUserLikes: ["admin"],
  NormalUserPosts: ["admin"],
  vendorUser: ["admin"],
  viewTribes: ["admin"],
  addTribe: ["admin"],
  viewTribe: ["admin"],
  editTribe: ["admin"],
  viewBookings: ["admin"],
  viewPrograms: ["admin", "vendor"],
  viewProgramDetails: ["admin", "vendor"],
  addProgram: ["admin", "vendor"],
  editProgram: ["admin", "vendor"],
  approveProgram: ["admin"],
  vendorBooking :["vendor"]
};
