import Vue from 'vue'
import {DataService,MultiPartDataService} from '@/config/axios'


// This function will create APIS
function  generateAPI(data){
    switch (data['method']){
        case 'GET':
            return  DataService.get(data.url,data.id,data.params)
        case 'POST':
            return  DataService.post(data.url,data.data)
        case 'DELETE':
            return DataService.delete(data.url,data.id)
        case 'PUT':
            return DataService.put(data.url,data.id,data.data)
        default:
            console.error('Invalid method')
    }


}

// This function will create APIS
function  fileUploadAPI(data,progress){
    return MultiPartDataService.post(data.url,data.data,progress)
}

Vue.prototype.fileUploadAPI = fileUploadAPI
Vue.prototype.generateAPI = generateAPI
