<template>
  <div
    :class="`formulate-input-element password-field formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <input
      :type="showPassword ? 'text' : 'password'"
      v-model="context.model"
      v-bind="context.attributes"
      autocomplete="off"      
      @blur="context.blurHandler"
      style="padding-right: 84px"
    />

    <div class="icon-button">
      <button type="button" v-b-tooltip.hover title="Generate" @click="makePassword">
        <i class="fas fa-cog"></i>
      </button>
      <button type="button" @click="showPassword = !showPassword"  v-b-tooltip.hover title="Show/Hide">
        <span class="visually-hidden"
          >{showPassword ? 'Hide password' : 'Show password'}</span
        >
        <div></div>
        <div class="icon-eye">
          <img v-show="!showPassword" src="@/assets/svg/eye.svg" alt />
          <img v-show="showPassword" src="@/assets/svg/eye-off.svg" alt />
        </div>
      </button>
    </div>
  </div>
</template>

<script> 
import {passwordGenerator} from "@/utils/passwordGenerator"
export default {
  name: "CustomPassword",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPassword: false,
      passwordGenerator:passwordGenerator
    };
  },
  methods: {
    makePassword(){
      this.context.model=passwordGenerator(12)
    }
  },
};
</script>

<style scoped>
.formulate-input-element {
  position: relative;
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.icon-button {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: none;
}
.icon-button button{
   border: 0;
  background: none;

}
</style>