import { DataService } from "@/config/axios";

export const state = {
  user: JSON.parse(localStorage.getItem("user")),
  userType: localStorage.getItem("userType"),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.user = newValue;
  },
  REMOVE_CURRENT_USER(state) {
    state.token = null;
    state.user = null;
    state.userType = null;
  },
  SET_USER_TYPE(state, value) {
    state.userType = value;
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.user;
  },
  userType(state) {
    return state.userType || null;
  },
  user(state) {
    return state.user || null;
  },
};

export const actions = {
  logIn({ commit }, { api }) {
    return new Promise((resolve, reject) => {
      DataService.post(api.url, api.data)
        .then((res) => {
          if (res.data.isAuth) {
            if (res.data.user) {
              localStorage.setItem("userType", "admin");
              localStorage.setItem("user", JSON.stringify(res.data.user));
              commit("SET_USER_TYPE", "admin");
              commit("SET_CURRENT_USER", res.data.user);
            } else {
              localStorage.setItem("userType", "vendor");
              commit("SET_USER_TYPE", "vendor");
              localStorage.setItem("user", JSON.stringify(res.data.vendor));
              commit("SET_CURRENT_USER", res.data.vendor);
            }
            localStorage.setItem("token", res.data.token);

            resolve(true);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          console.log("error");
          reject(err);
        });
    });
  },
  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    commit("REMOVE_CURRENT_USER");
  },
};
