<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <multiselect
      v-model="value"
      :tag-placeholder="tagPlaceholder"
      :placeholder="placeholder"
      label="label"
      track-by="value"
      :searchable="searchable"
      :options="optionData"
      :multiple="isMultiple"
      :taggable="isTaggable"
      @tag="addTag"
      :loading="isLoading"
      :internal-search="internalSearch"
      @search-change="asyncFind"
    ></multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "multiSelect",
  components: { Multiselect },
  props: {
    context: {
      type: Object,
      required: true,
      options: [],
      placeholder: "",
      tagPlaceholder: "",
      multiple: false,
      taggable: false,
      loading: false,
      internalSearch: false,
      searchable: false,
    },
  },
  data() {
    return {
      value: this.context.model,
    };
  },
  computed: {
    optionData() {
      return this.context.options.map((item) => {
        return { label: item.label, value: item.id };
      });
    },
    isMultiple() {
      return this.context.attributes.multiple;
    },
    isTaggable() {
      return this.context.attributes.taggable;
    },
    placeholder() {
      return this.context.attributes.placeholder;
    },
    tagPlaceholder() {
      return this.context.attributes.tagPlaceholder;
    },
    isLoading() {
      return this.context.attributes.loading;
    },
    internalSearch() {
      return this.context.attributes.internalSearch;
    },
    searchable() {
      return this.context.attributes.searchable;
    },
  },
  methods: {
    addTag() {},
    asyncFind(query) {
      this.isLoading = true;
      this.$emit("search", query);
    },
  },
  watch: {
    value(val) {
      console.log("This is val", val);
      if (val.length) {
        this.context.model = val.map((item) => {
          if (item.value.split("_").length === 1) {
            return item.value;
          } else {
            return item.value.split("_")[1];
          }
        });
      } else {
        this.context.model = val.value.split("_")[1];
      }
    },
  },
};
</script>

<style>
.multiselect__tags {
  border: unset !important;
  background-color: #f1f1f1 !important;
}
.multiselect__tags input {
  background-color: #f1f1f1 !important;
}
.multiselect__tag-icon:hover {
  background-color: #f8b538 !important;
}
</style>
